<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-8 text-center">
        <div class="text-center">
          <router-link to="/memberupdateinfo">
            <img src="https://papaimages.s3.ap-northeast-1.amazonaws.com/advOnSaleinfo/20221206095611.jpg" alt="" class="img-fluid"/>
          </router-link>
          <div class="text-start px-xl-3 px-xxl-6 mt-4">
            <p style="text-align: start;">注意事項：</p>
            <ul style="text-align: start;">
              <li class="mt-3">
                此活動於2023/1/1開始，將於每月5日上午10點發送Papawash生日點數至當月壽星會員帳戶。
                <br />※超過發送日的當月入會之生日會員，您的生日禮將於次年度起發送
                <br />（ex. 6/5後入會之6月生日會員，您的生日禮將於次年度起發送）
              </li>
              <li class="mt-2">
                會員需詳填完整會員資料，即可領取Papawash 100元生日點數的資格。
              </li>
              <li class="mt-2">
                會員點數抵用有效期間：匯入帳戶後的60天內，需使用完畢。
              </li>
              <li class="mt-2">
                點數抵用上限:
                <br />單筆訂單結帳金額500元以下，點數抵用上限100點。
                <br />單筆訂單結帳金額501~1000元，點數抵用上限250點。
                <br />單筆訂單結帳金額1001元以上，點數抵用上限為結帳金額之50%。
              </li>
              <li class="mt-2">
                特殊專案特惠活動商品，不適用折抵會員點數及會員消費再送點數活動。
              </li>
              <li class="mt-2">
                若有任何問題請洽詢Papawash客服專線電話:0809-080-038 或官網會員中心_聯絡客服，線上提問、官方LINE帳號或FB粉絲團 messenger私訊小編，
                <br />服務時間:週一至週五 9:00-12:00、13:30-18:00(國定例假日除外)。
              </li>
              <li class="mt-2">
                Papawash保留所有會員權益、相關活動及規定之更改權利；更改會於Papawash官網公告，恕不另行通知。
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
